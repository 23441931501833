import { Col, Form, Row, Select, Spin, Modal } from "antd";
import {
  getCentroTrabajoData,
  getInspeccionData,
  getProfesoresByZonasCentroTrabajo,
  getSubZonas,
  getZonas,
  getElaboracionCentroTrabajo,
  altaNumeroAlumnos,
} from "../../actions/escuela-action";

import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ListaImprimir from "./ListaImprimir";
import { toast } from "react-toastify";
import { useParams, useLocation } from "react-router-dom";
import AltaCentroTrabajo from "./AltaCentroTrabajo2";
import Supervision from "./Supervision";
import "./Imprimir.scss";
import axios from "axios";
const Imprimir = (props) => {
  //! Hooks
  const [isLoading, setisLoading] = useState(false);
  const [isImpresionButtonGrayedOut, setIsImpresionButtonGrayedOut] =
    useState(true);
  const zonas = useSelector((state) => state.escuela.zonas);
  const [userIdStorage, setuserIdStorage] = useState(null);
  const dispatch = useDispatch();
  // const idClaveZona = zonas.idClaveZona;
  const [subZonas, setsubZonas] = useState([]);
  const [userAccessSubZonas, setuserAccessSubZonas] = useState([]);
  const [userSubZonasPermited, setUserSubZonasPermited] = useState();
  const userData = sessionStorage.getItem("userAccessData");
  const userAccessDataObj = JSON.parse(userData);
  console.log("userAccessDataRG", userAccessDataObj);
  const { idClaveZona, escuela } = userAccessDataObj;
  const userIdClaveZonasCentroTrabajo =
    userAccessDataObj.idClaveZonaCentroTrabajo;
  // const userNumeroEscuela =userAccessDataObj.numeroEscuela;
  console.log("userIdClaveZonasCentroTrabajo", userIdClaveZonasCentroTrabajo);
  const userIdClaveZonasCentroTrabajoLenght=!userIdClaveZonasCentroTrabajo ? 0 : userIdClaveZonasCentroTrabajo.split(',').length;
  console.log('[...userIdClaveZonasCentroTrabajo]', [...userIdClaveZonasCentroTrabajo])
    console.log('userIdClaveZonasCentroTrabajoLenght', userIdClaveZonasCentroTrabajoLenght);
    const zonasCentroTrabajoArr = userIdClaveZonasCentroTrabajo.split(',');
    console.log('zonasCentroTrabajoArr', zonasCentroTrabajoArr)



  console.log("subZonas", subZonas);
  const [datosProfesores, setDatosProfesores] = useState([]);
  const [idClaveZonaCentroTrabajo, setIdClaveZonaCentroTrabajo] = useState("");
  const [numeroEscuela, setNumeroEscuela] = useState(null);
  const [newSelectValue, setNewSelectValue] = useState("");
  const [path, setpath] = useState(window.location.pathname);
  const [isSubZonasModalVisible, setIsSubZonasModalVisible] = useState(false);
  const [isDeleteSubZonasModalVisible, setIsDeleteSubZonasModalVisible] =
    useState(false);
  const [ZonaCentroTrabajo, setZonaCentroTrabajo] = useState("");


  /**
   * !useEffects
   */

  //? First useEffect, no dependencies
  useEffect(() => {
    if (idClaveZona === "0") {
      dispatch(getZonas());
    } else  {
      dispatch(getSubZonas(idClaveZona)).then((subZonas) => {
        console.log("subZonas", subZonas);
        const filteredData = subZonas.filter((item) =>
          zonasCentroTrabajoArr.includes(
            item.idClaveZonaCentroTrabajo.toString()
          )
        );
        setuserAccessSubZonas(filteredData);
      });
    }
    // dispatch(getZonas());
    setsubZonas([]);
    setDatosProfesores([]);
  }, []);

  //? Second useEffect, window.location.pathname dependency
  useEffect(() => {
    setNewSelectValue("");
    setsubZonas([]);
    setDatosProfesores([]);
    setIsImpresionButtonGrayedOut(true);
    setpath(window.location.pathname);

    setuserIdStorage(sessionStorage.getItem("userId"));
  }, [window.location.pathname]);

  //? Third useEffect, path as dependency
  useEffect(() => {
    if (userIdClaveZonasCentroTrabajoLenght === 1) {
      dispatch(getSubZonas(idClaveZona)).then((subZonas) => {
        console.log("subZonas1", subZonas);
        const filteredData = subZonas.filter((item) =>
          zonasCentroTrabajoArr.includes(
            item.idClaveZonaCentroTrabajo.toString()
          )
        );
        handleSingleSubZona(
          filteredData[0].idClaveZonaCentroTrabajo,
          filteredData[0].numeroEscuela
        );
      });
    }
    setsubZonas([]);
    setDatosProfesores([]);
  }, [path]);

  const handleZonas = (idClaveZona) => {
    setIsImpresionButtonGrayedOut(true);

    setsubZonas([]);
    setNewSelectValue(idClaveZona);
    setDatosProfesores([]);
    setTimeout(() => {
      dispatch(getSubZonas(idClaveZona)).then((subZonas) => {
        setsubZonas(subZonas);
      });
    }, 50);
  };

  const handleSubZonas = async () => {
    console.log("numeroEscuela", numeroEscuela);
    setIsSubZonasModalVisible(false);
    setIdClaveZonaCentroTrabajo(idClaveZonaCentroTrabajo);
    setNumeroEscuela(numeroEscuela);
    setisLoading(true);
    // setIsImpresionButtonGrayedOut(true);
    insertDownload();
    setDatosProfesores([]);

    const toBeDispatched = () => {
      if (path === "/imprimir/personal") {
        return getProfesoresByZonasCentroTrabajo(idClaveZonaCentroTrabajo);
      } else if (
        path === "/imprimir/altacentrotrabajo" &&
        numeroEscuela !== 0
      ) {
        return getCentroTrabajoData(idClaveZonaCentroTrabajo);
      } else {
        return getInspeccionData(idClaveZonaCentroTrabajo, numeroEscuela);
      }
    };
    const datosProfesores = await dispatch(
      toBeDispatched(idClaveZonaCentroTrabajo)
    );

    setDatosProfesores(datosProfesores);
    // setisLoading(false);
    // setIsImpresionButtonGrayedOut(false);
  };
  const handleSingleSubZona = async (
    idClaveZonaCentroTrabajo,
    numeroEscuela
  ) => {
    console.log("numeroEscuela", numeroEscuela);
    // setIsSubZonasModalVisible(false)
    setIdClaveZonaCentroTrabajo(idClaveZonaCentroTrabajo);
    setNumeroEscuela(numeroEscuela);
    setisLoading(true);
    setIsImpresionButtonGrayedOut(true);
    insertDownload();
    setDatosProfesores([]);
    console.log("pathhSSZ", path);
    const toBeDispatched = () => {
      if (path === "/imprimir/personal") {
        console.log("111111111111");
        return getProfesoresByZonasCentroTrabajo(idClaveZonaCentroTrabajo);
      } else if (
        path === "/imprimir/altacentrotrabajo" &&
        numeroEscuela !== 0
      ) {
        console.log("222222222222222");
        return getCentroTrabajoData(idClaveZonaCentroTrabajo);
      } else {
        console.log("33123132");
        return getInspeccionData(idClaveZonaCentroTrabajo, numeroEscuela);
      }
    };
    const datosProfesores = await dispatch(
      toBeDispatched(idClaveZonaCentroTrabajo)
    );

    setDatosProfesores(datosProfesores);
    // setisLoading(false);
    // setIsImpresionButtonGrayedOut(false);
  };

  console.log("userId", userIdStorage);
  console.log("idClaveZonaCentroTrabajo", idClaveZonaCentroTrabajo);
  const insertDownload = async () => {
    try {
      const { data } = await axios.post(
        `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/insertDowload?json=[{"idUsuario":${userIdStorage},"idClaveZonaCentroTrabajo":${idClaveZonaCentroTrabajo}}]`
      );
      // [{"idUsuario":1,"idClaveZonaCentroTrabajo":1}]
      return console.log("data", data);
    } catch (error) {
      console.log("insertDownload Error", error);
    }
  };

  console.log("userIdStorage", sessionStorage.getItem("userId"));
console.log('userAccessSubZonas.length', userAccessSubZonas.length)
  const handleSubZonasCancel = () => {
    setIsSubZonasModalVisible(false);
  };
  const handleSubZonasSelectChange = (a, b) => {
    console.log("handleSubZonasSelect", a, b.numeroEscuela);
    setIdClaveZonaCentroTrabajo(a);
    setNumeroEscuela(b.numeroEscuela);
    setIsSubZonasModalVisible(true);
  };
  const componentRef = useRef();

// Determine zoom percentage based on path
const zoomPercentage = 
path === "/imprimir/altacentrotrabajo" ? 39 :
path === "/imprimir/personal" || path === "/registromaestro" ? 56 :
50; // Default zoom if path doesn't match

const handlePrint = useReactToPrint({
content: () => componentRef.current,
copyStyles: true,
pageStyle: `
  @page {
    size: A4 landscape;
    margin: 10mm;
  }
  body {
    zoom: ${zoomPercentage}%;
    -webkit-print-color-adjust: exact;
  }
`,
});

  const insertaNumeroAlumnos = (dataNumAlumnos) =>
    dispatch(altaNumeroAlumnos(dataNumAlumnos));
  //Se agrego  el patha  a este stash   abajo
  const componentToBePrinted =
    numeroEscuela !== 0 &&
    Object.keys(datosProfesores).length > 0 &&
    path === "/imprimir/altacentrotrabajo" ? (
      <AltaCentroTrabajo
        datosProfesores={datosProfesores}
        path={path}
        setisLoading={setisLoading}
        setIsImpresionButtonGrayedOut={setIsImpresionButtonGrayedOut}
        insertaNumeroAlumnos={insertaNumeroAlumnos}
        idClaveZonaCentroTrabajo={idClaveZonaCentroTrabajo}
      />
    ) : (
      <Supervision
        setisLoading={setisLoading}
        setIsImpresionButtonGrayedOut={setIsImpresionButtonGrayedOut}
        datosSupervision={datosProfesores}
      />
    );
    console.log('userAccessSubzonas', userAccessSubZonas)
    console.log('idClaveZona', idClaveZona)

  return (
    <>
      <div>
        <Form>
          <Row>
            {idClaveZona === "0" ? (
              <>
                <Col flex="170px">
                  <Form.Item label="Elige zona">
                    <Select onChange={handleZonas} value={newSelectValue}>
                      {zonas.map(({ idClaveZona }) => {
                        return (
                          <Select.Option key={idClaveZona} value={idClaveZona}>
                            Zona {idClaveZona}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col flex="170px">
                  <>
                    {!!subZonas.length > 0 && (
                      <Form.Item label="Escuela">
                        <Select onChange={handleSubZonasSelectChange}>
                          {subZonas.map(
                            ({
                              idClaveZonaCentroTrabajo,
                              numeroEscuela,
                              etiqueta,
                            }) => (
                              <Select.Option
                                key={idClaveZonaCentroTrabajo}
                                value={idClaveZonaCentroTrabajo}
                                numeroEscuela={numeroEscuela}
                              >
                                {etiqueta}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    )}
                    <Modal
                      title="ADVERTENCIA!"
                      visible={isSubZonasModalVisible}
                      onOk={handleSubZonas}
                      onCancel={handleSubZonasCancel}
                    >
                      <p>
                        Al presionar Ok su consulta será registrada en nuestra
                        base de datos. Si usted no pertenece a la EST{" "}
                        {numeroEscuela}, presione Cancelar, en caso de ser la
                        escuela correcta presione Ok
                      </p>
                    </Modal>
                  </>
                </Col>{" "}
                <div
                  className={`boton-imprimir ${
                    isImpresionButtonGrayedOut ? "grayed-out" : null
                  }`}
                >
                  <button onClick={handlePrint}>Imprimir</button>
                </div>
                <div className="boton-imprimir">
                  {isLoading ? <Spin /> : null}
                </div>
              </>
            ) : null}

            {/* {!!userAccessSubZonas.length > 0 && (
              <>
                {" "}
                <Col flex="170px">
                {idClaveZona !== "0" && (
                  <Form.Item label="Escuela">
                    <Select onChange={handleSubZonasSelectChange}>
                      {userAccessSubZonas.map(
                        ({
                          idClaveZonaCentroTrabajo,
                          numeroEscuela,
                          etiqueta,
                        }) => (
                          <Select.Option
                            key={idClaveZonaCentroTrabajo}
                            value={idClaveZonaCentroTrabajo}
                            numeroEscuela={numeroEscuela}
                          >
                            {numeroEscuela}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </Form.Item>)}
                  <Modal
                    title="ADVERTENCIA!"
                    visible={isSubZonasModalVisible}
                    onOk={handleSubZonas}
                    onCancel={handleSubZonasCancel}
                  >
                    <p>
                      Al presionar Ok su consulta será registrada en nuestra
                      base de datos. Si usted no pertenece a la EST{" "}
                      {numeroEscuela}, presione Cancelar, en caso de ser la
                      escuela correcta presione Ok
                    </p>
                  </Modal>
                </Col>{" "}
                <Col>
                  <div
                    className={`boton-imprimir ${
                      isImpresionButtonGrayedOut ? "grayed-out" : null
                    }`}
                  >
                    <button onClick={handlePrint}>Imprimir</button>
                  </div>
                  <div className="boton-imprimir">
                    {isLoading ? <Spin /> : null}
                  </div>
                </Col>
              </>
            )} */}
             {!!userAccessSubZonas.length > 0 && (
              <>
              {" "}
              <Col flex="170px">
              {idClaveZona !== "0" && zonasCentroTrabajoArr.length > 1 && (
                <Form.Item label="Escuela">
                  <Select onChange={handleSubZonasSelectChange}>
                    {userAccessSubZonas.map(
                      ({
                        idClaveZonaCentroTrabajo,
                        numeroEscuela,
                        etiqueta,
                      }) => (
                        <Select.Option
                          key={idClaveZonaCentroTrabajo}
                          value={idClaveZonaCentroTrabajo}
                          numeroEscuela={numeroEscuela}
                        >
                           {etiqueta}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>)}
                


                <Modal
                  title="ADVERTENCIA!"
                  visible={isSubZonasModalVisible}
                  onOk={handleSubZonas}
                  onCancel={handleSubZonasCancel}
                >
                  <p>
                    Al presionar Ok su consulta será registrada en nuestra
                    base de datos. Si usted no pertenece a la EST{" "}
                    {numeroEscuela}, presione Cancelar, en caso de ser la
                    escuela correcta presione Ok
                  </p>
                </Modal>
              </Col>{" "}
              <Col>
                <div
                  className={`boton-imprimir ${
                    isImpresionButtonGrayedOut ? "grayed-out" : null
                  }`}
                >
                  <button onClick={handlePrint}>Imprimir</button>
                </div>
                <div className="boton-imprimir">
                  {isLoading ? <Spin /> : null}
                </div>
              </Col>
            </>
          )}
          </Row>
        </Form>
      </div>
      <div ref={componentRef}>
        {Object.keys(datosProfesores).length > 0 &&
          (path === "/imprimir/personal" ? (
            <ListaImprimir
              datosProfesores={datosProfesores}
              path={path}
              setisLoading={setisLoading}
              setIsImpresionButtonGrayedOut={setIsImpresionButtonGrayedOut}
            />
          ) : (
            componentToBePrinted
          ))}
      </div>
    </>
  );
};

export default Imprimir;
