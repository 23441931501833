import { BrowserRouter, Route, NavLink } from "react-router-dom";
import { Layout, Menu, Button } from "antd";
import {
  HomeOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  PrinterOutlined,
  RedoOutlined,
  SearchOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
// import MainContent from "./MainContent";
import { useState } from "react";
// import PrivateRoute from "./components/PrivateRoute";
import Home from "../components/Home/Home";
import Login from "../components/Login/Login";
import AltaContainer from "../containers/altaContainer";
// import AltaPersonalCake from "../page/personal/Alta";
import Actualizaciones from "../page/personal/Actualizaciones";
import ImprimirMaestro from "../page/personal/ImprimirMaestro";
import Busqueda from "../page/personal/Busqueda";
import AltaCentroTrabajo from "../page/personal/AltaCentroTrabajo";
import Imprimir from "../page/personal/Imprimir";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ActualizaAsignatura from "../page/personal/ActualizaAsignatura";
import "./App.scss";
import SubMenu from "antd/lib/menu/SubMenu";
import ReporteInspeccion from "../page/personal/ReporteInspeccion";
import RGContainer from "../containers/RGContainer";
// import altaContainer from "../containers/altaContainer";

const { Header, Footer, Content } = Layout;

function App(props) {

  const [user, setuser] = useState({});

  const sesionActive = sessionStorage.getItem("isSessionActive");
  const handleLogout = () => {
    sessionStorage.removeItem("isSessionActive");
    setuser({});
    // form.resetFields();
  };

  return (
    <div>
      <ToastContainer
        className="toast-conntainer"
        position="top-center"
        autoClose={5000}
        hideProgressBar
        progress={undefined}
      />
      {user.sesion !== "true" && !sesionActive ? (
        <Login setuser={setuser}  />
      ) : null}
      {/* <PrivateRoute path='/content' component={MainContent} /> */}
      {/* <Route path='/home' > */}

      {(user.sesion === "true" || sesionActive) && (
        <Layout className="app-layout">
          <BrowserRouter>
            {/* <Switch> */}
            <Header className="header">
              <NavLink exact activeClassName="active" to="/">
                <Button type="primary" danger onClick={handleLogout}>
                  Cerrar sesión
                </Button>
              </NavLink>

              {/* <div className='bienvenido'>Bienvenido {name}</div> */}

              {/* <ul className="menu cf" > */}
              <Menu mode="horizontal">
                <Menu.Item icon={<HomeOutlined style={{ fontSize: 23 }} />}>
                  <NavLink exact activeClassName="active" to="/">
                    INICIO{" "}
                  </NavLink>
                </Menu.Item>
                <Menu.Item icon={<UserAddOutlined style={{ fontSize: 23 }} />}>
                  <NavLink exact activeClassName="active" to="/alta">
                    ALTA{" "}
                  </NavLink>
                </Menu.Item>

                <Menu.Item
                  icon={<UserDeleteOutlined style={{ fontSize: 23 }} />}
                >
                  <NavLink exact activeClassName="active" to="/baja">
                    BAJA{" "}
                  </NavLink>
                </Menu.Item>
                <Menu.Item icon={<RedoOutlined style={{ fontSize: 23 }} />}>
                  <NavLink exact activeClassName="active" to="/actualizaciones">
                    ACTUALIZAR{" "}
                  </NavLink>
                </Menu.Item>
                <Menu.Item icon={<SearchOutlined style={{ fontSize: 23 }} />}>
                  {" "}
                  <NavLink exact activeClassName="active" to="/busqueda">
                    BÚSQUEDA
                  </NavLink>
                </Menu.Item>

                <SubMenu
                  icon={<PrinterOutlined style={{ fontSize: 23 }} />}
                  style={{ fontSize: 23 }}
                  title="IMPRIMIR"
                >
                  <Menu.Item>
                    <NavLink
                      exact
                      activeClassName="active"
                      to="/imprimir/personal"
                    >
                      Plantilla de Personal{" "}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item>

<NavLink
  exact
  activeClassName="active"
  to="/registromaestro"
>
  Registro de Maestro{" "}
</NavLink>
</Menu.Item>
                  <Menu.Item>
                    <NavLink
                      exact
                      activeClassName="active"
                      to="/imprimir/altacentrotrabajo"
                    >
                      Portada Centro de Trabajo{" "}
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item icon={<SearchOutlined style={{ fontSize: 23 }} />}>
                  {" "}
                  <NavLink exact activeClassName="active" to="/asignacion">
                    ASIGNACIÓN
                  </NavLink>
                </Menu.Item>
                <SubMenu
                  icon={<SnippetsOutlined style={{ fontSize: 23 }} />}
                  //<SnippetsOutlined />
                  style={{ fontSize: 23 }}
                  title="REPORTE"
                >
                  <Menu.Item>
                    <NavLink
                      exact
                      activeClassName="active"
                      to="/reporte/general"
                    >
                      General{" "}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item>
                    <NavLink
                      exact
                      activeClassName="active"
                      to="/reporte/inspeccion"
                    >
                      Inspeccion{" "}
                    </NavLink>
                  </Menu.Item>
                </SubMenu>

                {/* <SubMenu icon={ <RedoOutlined style={{ fontSize: 23 }} />}style={{ fontSize: 23 }} title="ACTUALIZAR"> */}

                {/* <Menu.Item >
                <NavLink exact activeClassName='active' to="/actualizaciones">Actualización de Personal </NavLink> 
                     </Menu.Item>
                     <Menu.Item>
                    <NavLink exact activeClassName='active' to='altacentrotrabajo'>Actualización de Centro de Trabajo </NavLink>

                     </Menu.Item>
              </SubMenu> */}

                {/* <Menu.Item icon={<PrinterOutlined style={{ fontSize: 23 }} />}> <NavLink exact activeClassName='active' to="imprimir">IMPRIMIR</NavLink></Menu.Item> */}
              </Menu>
              {/* </ul> */}
            </Header>
            <Content className="main">
              <Route path="/" exact component={Home} />
              <Route path="/alta" exact component={AltaContainer} />
              {/* <Route path="/altaCake" exact component={AltaPersonalCake} /> */}
              <Route path="/baja" exact component={Actualizaciones} />
              <Route
                path="/actualizaciones"
                exact
                component={Actualizaciones}
              />
              <Route path="/busqueda" exact component={Busqueda} />
              <Route path="/asignacion" exact component={ActualizaAsignatura} />
              <Route
                path="/imprimir/:tipoImpresion"
                exact
                component={Imprimir}
              />
              <Route
                path="/altacentrotrabajo"
                exact
                component={AltaCentroTrabajo}
              />
              <Route
                path="/registromaestro"
                exact
                component={ImprimirMaestro}
              />
              <Route path="/reporte/general" exact component={RGContainer} />
              <Route
                path="/reporte/inspeccion"
                exact
                component={ReporteInspeccion}
              />
            </Content>
            <Footer>
              Copyright: Quedan Reservados todos los derechos  para el  Departamento de Secundarias
              Técnicas Morelos.
            </Footer>
            {/* </Switch> */}
          </BrowserRouter>
        </Layout>
      )}

      {/* </Route> */}
      {/* <Route path='*' >
          <Redirect to={{ pathname: '/', path: window.location.pathname }} />
        </Route> */}
    </div>
  );
}

export default App;
