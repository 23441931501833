import React, { useEffect, useState } from "react";
import "./supervision.scss";
import plantillaPersonalInspeccion from "../../images/PlantillaPersonalInspeccion.png";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
//comentario
export default function Supervision(props) {
  const { setisLoading, setIsImpresionButtonGrayedOut } = props;
  const [identificacionValues, setidentificacionValues] = useState({});
  const [editableIdentificacion, setEditableIdentificacion] = useState(false)
  const { personalSupervisionInspeccion, concentradoPersonalInspeccion, identificacion, clavesZonasCentroTrabajoById } = props.datosSupervision;
  const { todosLosPuestos: todosLosPuestosConcentrado } = concentradoPersonalInspeccion;
  const { todosLosPuestos: todosLosPuestosPersonal } = personalSupervisionInspeccion;
  const { nombreEscuela, direccion, localidad, municipio, codigoPostal, telefono, fechaFundacion, correoElectronico, elaboro, autorizo, vistoBueno, dest } = identificacionValues;



  useEffect(() => {
    setidentificacionValues(identificacion)
    setisLoading(false);
    setIsImpresionButtonGrayedOut(false);
  }, []);

  

  const handleIdentificacionValues = (e) => {
    const name = e.target.attributes.name.value;
    const value = e.target.value;
    console.log('name', name)
    console.log('value', value)
    console.log('value', value)
    setEditableIdentificacion(true);
    setidentificacionValues({ ...identificacionValues, [name]: value });
    setEditableIdentificacion(true);
  };
console.log('identificacionValues', identificacionValues)
  const notify = (info) => toast.dark(info);
  const onSubmitIdentificacion = (e) => {
    e.preventDefault();
    const result = JSON.stringify(identificacionValues);


    axios
      .post(
        `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/updateIdentificacionCentroTrabajo?json=[${result}]`
      )
      .then(function (response) {
        notify("datos actualizados");
        setEditableIdentificacion(false);
      })
      .catch(function (error) {
        notify("Datos no actualizados");
        console.log(error);
      });
  };

  return (

    < div className="wrapper-supervision">

      <img className="banner-inspeccion"

        alt="bannerPersonalInspeccion"
     
        src={plantillaPersonalInspeccion}
      />




      <table className="tabla-alta-centro-supervision identificacion">
        <thead>
          <tr>
            <th colSpan="2">Identificación </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Nombre</td>
            <td>{clavesZonasCentroTrabajoById.etiqueta}</td>
          </tr>
          <tr>
            <td>Clave del centro de trabajo</td>
            <td>{clavesZonasCentroTrabajoById.claveCentroTrabajo}</td>
          </tr>
          <tr>
            <td>Dirección</td>
            <td>{direccion}</td>
          </tr>
          <tr>
            <td>Localidad</td>
            <td>{localidad}</td>
          </tr>
          <tr>
            <td>Municipio</td>
            <td>{municipio}</td>
          </tr>
          <tr>
            <td>Código Postal</td>
            <td>{codigoPostal}</td>
          </tr>
          <tr>
            <td>Teléfono</td>
            <td>{telefono}</td>
          </tr>
          <tr>
            <td>Fecha de Fundación</td>
            <td>{fechaFundacion}</td>
          </tr>
          <tr>
            <td>Correo Electrónico</td>
            <td>{correoElectronico}</td>
          </tr>
        </tbody>
      </table>

      <table className="tabla-alta-centro-supervision personal-supervision">
        <thead>
          <tr>
            <th >Personal de Inspección</th>
            <td>Total</td>
          </tr>
        </thead>
        <tbody>
          {todosLosPuestosPersonal.map((puesto) => {
            return <tr>
              <td>{puesto.nombre}</td>
              <td>{puesto.totalM + puesto.totalV+ puesto.totalD}</td>
            </tr>
          })}

        </tbody>
      </table>

      <table className="tabla-alta-centro-supervision concentrado-personal">
        <thead>
          <tr>
            <th >Concentrado de personal por estructura</th>
            <th >Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Escuelas a su cargo</td>
            <td>{concentradoPersonalInspeccion.escuelasCargo}</td>
          </tr>
          {todosLosPuestosConcentrado.map((puesto) => {
            return <tr>
              <td>{puesto.nombre}</td>
              <td>{puesto.totalM + puesto.totalV+ puesto.totalD}</td>
            </tr>
          })}
        </tbody>
      </table>

      <form
        onSubmit={onSubmitIdentificacion}
        className=" fecha-elaboracion"
      >
        <table className=" tabla-alta-centro-supervision ">
          <thead>
            <tr>
              <th colSpan="4">Fecha de elaboración: {moment().lang("es").format('LL').toString()}.</th>
            </tr>
          </thead>
          <tbody>
            <tr height="120px">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <textarea
                  // size="40"
                  // maxLength="30"
                  type="text"
                  name="elaboro"
                  onChange={handleIdentificacionValues}
                  value={identificacionValues.elaboro}
                />
              </td>
              <td>
                <textarea
                  type="text"
                  name="autorizo"
                  onChange={handleIdentificacionValues}
                  value={identificacionValues.autorizo}
                />
              </td>
              <td>
                <textarea
                  type="text"
                  name="vistoBueno"
                  onChange={handleIdentificacionValues}
                  value={identificacionValues.vistoBueno}
                />
              </td>
              <td>
                <textarea
                  type="text"
                  name="dest"
                  onChange={handleIdentificacionValues}
                  value={identificacionValues.dest}
                />
              </td>
            </tr>


            <tr>
              <td>Elaboró</td>
              <td>Autorizó</td>
              <td>Vo.Bo.</td>
              <td>Depto. De Secundarias Técnicas</td>
            </tr>
          </tbody>
          <tfoot>
            {identificacion && (
              <button
                style={
                  editableIdentificacion
                    ? { cursor: "pointer" }
                    : { pointerEvents: "none", opacity: "0.5" }
                }
                type="submit"
              >
                Actualizar
              </button>
            )}
          </tfoot>
        </table><h1>{identificacionValues.dest}</h1>
      </form>

    </div>
  );
}
